import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	ImportExample,
	Code,
	DocsTable,
	Header,
} from "../../../../../src/docs";
import { TextContainer } from "../../text/text-container/text-container";
import { Paragraph } from "../../text/paragraph/paragraph";
import { Ul } from "../../text/list/list";
import { InlineText } from "../../text/inline-text/inline-text";
import { Icon } from "../../visuals/icons/icons";
import { IconDelete, IconDesktop, IconPotentialIssue } from "../../visuals/icons/icons-list";
import { Button } from "../../actions-and-controls/button/button";
import { H2, H4 } from "../../text/heading/heading";
import { Card } from "../../structure/card/card";
import { Content } from "../../structure/content/content";
import { Tooltip, TooltipProps } from "./tooltip";

export const Meta: DocPageMeta = {
	category: "Overlay",
	title: "Tooltip",
	notepad: "https://hackmd.io/4WbGPzesRMqCLPQ0gw6fBQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Tooltip"
			subTitle="A tooltip is a short, context-specific text message that appears temporarily when a user hovers or focuses on an element."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample lab component={Tooltip} />
				<Header.H2> Tooltip vs. Popover</Header.H2>
				<Paragraph>
					To clarify the differences between <Code>Tooltip</Code> and <Code>Popover</Code>, a short
					description is provided.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Component name</th>
							<th>Usage</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Tooltip</td>
							<td>
								A tooltip is a short context-specific text message that appears temporarily when a
								user hovers or focuses on an element.
							</td>
						</tr>
						<tr>
							<td>Popover</td>
							<td>
								A popover is a customizable container that may contain interactive elements. It
								remains visible until the user clicks the close button, clicks outside of it, or
								tabs to the next focusable element.{" "}
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					A <Code>Tooltip</Code> is a concise contextual message connected to an attached element,
					such as an icon or button, which offers supplementary information about it. By default, a{" "}
					<Code>Tooltip</Code> is hidden and become visible when hovered over or focused on an
					attached element. A <Code>Tooltip</Code> should be used sparingly, as it provides limited
					and specific information to an attached element.
				</Paragraph>
				<Paragraph>
					A <Code>Tooltip</Code> consists of two elements:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Caret tip</b>: a guide that directs the user's attention to the trigger that
							activated the <Code>Tooltip</Code>.
						</>,
						<>
							<b>Container</b>: the area that displays the additional information.
						</>,
					]}
				/>
				<Header.H3>Variant</Header.H3>
				<Paragraph>
					Use the <Code>variant</Code> property to find out which variant suits your use case. The
					following sections explain how to use them.
				</Paragraph>
				<Paragraph>
					Pay attention to user interaction with the <Code>Tooltip</Code>.{" "}
					<a href="#interactive-variant">Interactive</a> variants are displayed only on focus state,
					whereas <a href="#text-variant">text</a> and <a href="#icon-only-variant">icon only</a>{" "}
					variants require keyboard users to navigate to the attached element and press the Space
					Bar or Enter keys to display them.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Variant</th>
							<th>Example</th>
							<th>Use case</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<a href="#text-variant">Text</a>
							</td>
							<td>
								<InlineText>
									<Tooltip
										variant={{ type: "text" }}
										content="The Digital Certainty Index (DCI) is a measure of the quality and potential impact of a site's digital presence."
									>
										DCI
									</Tooltip>
								</InlineText>
							</td>
							<td>
								Provides non-essential supplemental information to help users make decisions. For
								example, it can be used when data is missing from a table cell.
							</td>
						</tr>
						<tr>
							<td>
								<a href="#icon-only-variant">Icon only</a>
							</td>
							<td>
								<Tooltip variant={{ type: "icon-only" }} content="Desktop device">
									<Icon>
										<IconDesktop />
									</Icon>
								</Tooltip>
							</td>

							<td>Describes the meaning or purpose of icons without visible labels.</td>
						</tr>
						<tr>
							<td>
								<a href="#interactive-variant">Interactive</a>
							</td>
							<td>
								<Tooltip variant={{ type: "interactive" }} content="Delete item">
									<Button onClick={() => console.log("clicked")}>
										<Icon>
											<IconDelete />
										</Icon>
									</Button>
								</Tooltip>
							</td>
							<td>
								Describes the function or action of an interactive UI element, such as a button.{" "}
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H3>Text variant</Header.H3>
				<Paragraph>
					Use the <Code>text</Code> variant to provide concise, relevant, and helpful information.
					The The tooltip's text should be clear and standalone, needing no additional explanation.
					Nest <Code>Tooltip</Code> inside the text's HTML or Fancy tags and wrap it around the
					string or number. Place the information in the <Code>content</Code> property of the
					component.
				</Paragraph>
				<Example fn={textExample} />
				<Header.H4>Use a text variant in a card header</Header.H4>
				<Paragraph>
					This is another contextual example with a <Code>text</Code> variant. It shows how to add a{" "}
					<Code>Tooltip</Code> to a{" "}
					<GatsbyLink to="/lib/components/Structure/Card#header">Card header</GatsbyLink>. However,
					if the user is unfamiliar with the title in the{" "}
					<GatsbyLink to="/lib/components/Structure/Card#header">Card header</GatsbyLink>, clearly
					display an explanation using <Code>small</Code> and <Code>subtle</Code>{" "}
					<GatsbyLink to="/lib/components/Text/Inline text">Inline text</GatsbyLink> below a title
					in the <GatsbyLink to="/lib/components/Structure/Card#header">Card header</GatsbyLink>{" "}
					instead. See <a href="#best-practices">Best Practices</a>.
				</Paragraph>
				<Example fn={cardHeaderExample} />
				<Header.H4>Change an icon in a text variant</Header.H4>
				<Paragraph>
					Use the <Code>icon</Code> property to change the icon in a <Code>Tooltip</Code>. The
					default icon is <Code>IconHelpOutline</Code>, aliased as <Code>Tooltip.icon</Code>. It is
					highly recommended to stick to the default icon for better user recognition and
					consistency.
				</Paragraph>
				<Example fn={tooltipIconExample} />
				<Header.H4>Change an icon size in a text variant</Header.H4>
				<Paragraph>
					Use the <Code>size</Code> property of the{" "}
					<GatsbyLink to="/lib/components/Visuals/Icon">Icon</GatsbyLink> component to adjust the
					size of the tooltip icon. This enables you to match the size of the tooltip icon with its
					associated text. The default value is <Code>medium</Code>, aliased as{" "}
					<Code>Tooltip.iconSize</Code>.
				</Paragraph>
				<Example fn={tooltipIconSizeExample} />
				<Header.H4>Use a text variant to explain no data</Header.H4>
				<Paragraph>
					Use a <a href="#text-variant">text</a> variant to explain the absence of data in a table
					cell or similar location, with a brief explanation provided.
				</Paragraph>
				<Example fn={missingDataTextExample} />
				<Header.H3>Icon only variant</Header.H3>
				<Paragraph>
					Use the <Code>icon-only</Code> variant for clear and concise descriptions of static icons.
					Wrap the <Code>Tooltip</Code> around the <Code>Icon</Code> and put the label in the{" "}
					<Code>content</Code> property.
				</Paragraph>
				<Paragraph>
					Only provide a static <GatsbyLink to="/lab/components/Visuals/Icons">icon</GatsbyLink>{" "}
					with a <Code>Tooltip</Code>, instead of a visible label, when:
				</Paragraph>
				<Ul
					items={[
						"The label doesn't contain information essential to task completion.",
						"Placing label in the UI would add significant visual clutter.",
					]}
				/>
				<Example fn={iconOnlyExample} />
				<Header.H4>Use an icon only variant to explain no data</Header.H4>
				<Paragraph>
					The <a href="#icon-only-variant">icon only</a> variant can be used when there is no
					accompanying text, but only as a last resort due to technical difficulties. It is
					recommended to include a text snippet.
				</Paragraph>
				<Example fn={missingDataIconOnlyExample} />
				<Header.H3>Interactive variant</Header.H3>
				<Paragraph>
					For interactive UI elements lacking a label, utilize the <Code>interactive</Code>{" "}
					<Code>Tooltip</Code> to clarify their purpose or action. Wrap the <Code>Tooltip</Code>{" "}
					around the element and provide clarification in the <Code>content</Code> property. An
					example of this is an{" "}
					<a href="https://fancy.siteimprove.com/lib/components/Actions%20and%20controls/Button#icon">
						icon-only button
					</a>
					, as shown below.
				</Paragraph>
				<Paragraph>
					Only provide interactive UI elements with an{" "}
					<GatsbyLink to="/lab/components/Visuals/Icons">icon</GatsbyLink>, instead of a visible
					label, when:
				</Paragraph>
				<Ul
					items={[
						"Space in the UI is limited and/or the UI element is repeated many times on the page.",
						"The icon is universally known, such as a trash can for deleting.",
					]}
				/>
				<Example fn={interactiveExample} />
				<Header.H3>Placement</Header.H3>
				<Paragraph>
					Position a <Code>Tooltip</Code> using the <Code>placement</Code> property to avoid
					obstructing relevant information crucial to the user's task. The <Code>Tooltip</Code>{" "}
					offers 12 placement options, with <Code>top</Code> as the default. A <Code>Tooltip</Code>{" "}
					can also be positioned to the <Code>right</Code>, <Code>left</Code>, <Code>bottom</Code>{" "}
					of the trigger element.{" "}
				</Paragraph>
				<Paragraph>
					Keep in mind that the <Code>Tooltip</Code> will only appear in the preferred placement if
					there's enough space available.
				</Paragraph>
				<Example fn={placementExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs<TooltipProps>
					component={Tooltip}
					initialProps={{
						variant: { type: "text" },
						content: "Use a tooltip to briefly clarify the function or meaning of a UI element.",
						children: "Tooltip",
					}}
					exampleValues={{
						variant: {
							text: { type: "text" },
							"icon-only": { type: "icon-only" },
							interactive: { type: "interactive" },
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						A <Code>Tooltip</Code> is only necessary when an element on the user interface is not
						immediately clear and the user needs further explanation.
					</Paragraph>
					<Paragraph>
						Use a <Code>Tooltip</Code> when the information is contextually relevant, valuable, and
						helpful. They can be used to clarify the function of buttons that only consist of icons,
						explain missing data, or provide additional context for the user to make decisions.
					</Paragraph>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						A <Code>Tooltip</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<H4>Table</H4>Use a <Code>Tooltip</Code> in a{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Column header">
									Column header
								</GatsbyLink>{" "}
								to clarify the meaning of the data. For instance, in the case of the "Search Volume"
								column in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F446001%2FKeywordDiscovery%2FIndex%3FTabSelect%3DIndex.Table%26lang%3Den-US">
									SEO Keyword Discovery
								</a>
								, the <Code>Tooltip</Code> can explain it as the monthly search count for a keyword.
							</>,
							<>
								<H4>Form</H4>Use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form Element Wrapper
								</GatsbyLink>{" "}
								with help text to present crucial task information, and a <Code>Tooltip</Code> for
								further explanation of the meaning of the label or the need for requested
								information. Avoid repeating the help text in the <Code>Tooltip</Code>.
							</>,
							<>
								<H4>Highlight important data and metrics</H4>Tooltips can be utilized in the{" "}
								<GatsbyLink to="/lab/components/Text/Highlight">Highlight</GatsbyLink> and{" "}
								<GatsbyLink to="/lab/components/Data visualization/DCI Gauge">DCI Gauge</GatsbyLink>{" "}
								components to emphasize crucial data and metrics. They can also clarify taxonomy or
								product terminology, for example, in the{" "}
								<a href="https://my2.siteimprove.com/PPC/34635/Custom/Overview/Index?TabSelect=History.Performance">
									Ads Overview
								</a>
								, a <Code>Tooltip</Code> is used to explain "Return on Ad Spend".
							</>,
							<>
								<H4>Card header</H4>A <Code>Tooltip</Code> can be used for a title in the{" "}
								<GatsbyLink to="/lib/components/Structure/Card#header">Card header</GatsbyLink>.
								However, it is recommended to display the explanation using <Code>small</Code> and{" "}
								<Code>subtle</Code>{" "}
								<GatsbyLink to="/lib/components/Text/Inline text">Inline text</GatsbyLink> below a
								title in the{" "}
								<GatsbyLink to="/lib/components/Structure/Card#header">Card header</GatsbyLink> for
								clarity. As an example, in Analytics, the definition of{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAccessibility%2F446001%2FNextGen%2FOverview%3FpageSegments%3D%26conformance%3D0%252C1%26siteGoal%3D0%252C1%26lang%3Den-US">
									Key Metrics
								</a>{" "}
								is displayed below a title in the{" "}
								<GatsbyLink to="/lib/components/Structure/Card#header">Card header</GatsbyLink>.
							</>,
							<>
								<H4>Card content</H4> When the information is compact in the{" "}
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>, you can use the{" "}
								<a href="#text-variant">text variant</a> with{" "}
								<GatsbyLink to="/lib/components/Text/Inline text">Inline text</GatsbyLink>. For
								example, in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAccessibility%2F446001%2FNextGen%2FOverview%3FpageSegments%3D%26conformance%3D0%252C1%26siteGoal%3D0%252C1%26lang%3Den-US">
									Accessibility Overview
								</a>
								, the <Code>Tooltip</Code> is used with the{" "}
								<GatsbyLink to="/lib/components/Text/Inline text">Inline text</GatsbyLink> "Progress
								towards site target" above the{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Progress Bar">
									Progress bar
								</GatsbyLink>
								.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Tooltips' text wraps automatically if its content exceeds the maximum width of 48ch.
							</>,
							<>
								Ensure consistency in using tooltips, and consider if similar elements also need
								one.
							</>,
							<>
								Avoid styling the content in tooltips, stick to plain text and avoid images, complex
								styling, or links that may distract the user from the message.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								displaying important information needed to complete a task. The important
								information should be visible on the page and not hidden in a <Code>Tooltip</Code>.
								Use subheadings, inline text, or persistent.
							</>,
							<>
								including actions. Use the{" "}
								<GatsbyLink to="/lab/components/Overlay/Popover">Popover</GatsbyLink> and disclosure
								pattern for elements like{" "}
								<GatsbyLink to="/lib/components/Actions and controls/Button">Button </GatsbyLink> or{" "}
								<GatsbyLink to="/lib/components/Navigation/Link">Link</GatsbyLink> that need to be
								part of additional information.
							</>,
							<>
								presenting longer help content. Use a{" "}
								<GatsbyLink to="/lib/components/Feedback/Learn info box">Learn Info Box</GatsbyLink>{" "}
								component or the <a href="https://help.siteimprove.com/support/home">Help Center</a>
								.
							</>,
							<>introducing new features, and explaining how to use them.</>,
							<>
								displaying rrror or status messages, images, interactive content, obvious
								interactions, or irrelevant information.
							</>,
							<>
								a page title requires additional explanation, it may not be suitable. Rewrite the
								title accordingly.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								A <Code>Tooltip</Code> may reduce UI clutter, but their use can greatly decrease
								accessibility for screen reader users. Always consider alternatives before using a
								Tooltip.
							</>,
							<>
								A <Code>Tooltip</Code> must only include text and avoid interactive elements.
							</>,
							<>
								Avoid covering important content and place vital information in a{" "}
								<Code>Tooltip</Code>.
							</>,
							<>
								Make sure the information in the <Code>Tooltip</Code>
								accurately describes the attached element.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Ul
						items={[
							<>
								Avoid adding a <Code>title</Code> attribute to elements within a{" "}
								<Code>Tooltip</Code> to avoid duplicate tooltips.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								Tooltips should be concise with only essential information to understand the
								element. Ideally no more than one or two sentences. See{" "}
								<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/UX/pages/2050590210/Tooltips">
									writing example
								</a>{" "}
								.
							</>,
							<>Ensure the content is clear and understandable without technical terms.</>,
							<>
								The tooltip should be relevant to the attached element, avoiding redundant
								information.
							</>,
							<>Use sentence case, and only use periods if the text contains complete sentences.</>,
						]}
					/>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 47.0.0</Header.H3>
				<Paragraph>
					Variants <Code>icon-short-label</Code> and <Code>icon-long-label</Code> merged to a common{" "}
					<Code>icon-only</Code> variant.
				</Paragraph>
				<Header.H3>Version 0.0.x</Header.H3>
				<Ul
					items={[
						<>
							It's now required to choose from one of four <Code>Tooltip</Code> variants:
							<Ul
								items={[
									<>
										<Code>text</Code>
									</>,
									<>
										<Code>icon-short-label</Code>
									</>,
									<>
										<Code>icon-long-label</Code>
									</>,
									<>
										<Code>interactive</Code>
									</>,
								]}
							/>
						</>,
						<>
							The <Code>interactive</Code> variant replaces the <Code>Instant Tooltip Wrapper</Code>{" "}
							component.
						</>,
						<>
							The <Code>anchor</Code> property has been removed. The <Code>Tooltip</Code> should now
							always be wrapped around its associated UI element.
						</>,
						<>
							The <Code>Tooltip</Code> now always comes with an <Code>aria-label</Code>. It’s
							(partly) set with the <Code>variant</Code> property’s <Code>learnMoreAbout</Code>{" "}
							parameter for the <Code>text</Code> and <Code>icon-long-label</Code> variants. It’s
							automatically set for the <Code>icon-short-label</Code> and <Code>interactive</Code>{" "}
							variants.
						</>,
						<>
							Eight new value options have been added to the <Code>placement</Code> property.
						</>,
						<>
							It’s now possible to change both the Tooltip icon and its size, when using the{" "}
							<Code>text</Code> variant, with the <Code>icon</Code> property.
						</>,
					]}
				/>
			</TextContainer>
		</ContentSection>
	</>
);

const textExample = () => (
	<InlineText>
		<Tooltip
			variant={{ type: "text" }}
			content="The Digital Certainty Index (DCI) is a measure of the quality and potential impact of a site's digital presence."
		>
			DCI
		</Tooltip>
	</InlineText>
);

const cardHeaderExample = () => (
	<Card>
		<Card.Header>
			<H2>
				<Tooltip
					variant={{ type: "text" }}
					content="Web Content Accessibility Guidelines (WCAG) is developed through the W3C process in cooperation with individuals and organizations around the world, with a goal of providing a single shared standard for web content accessibility that meets the needs of individuals, organizations, and governments internationally."
				>
					WCAG
				</Tooltip>
			</H2>
		</Card.Header>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ultricies nisl neque, ut
				feugiat arcu dapibus nec. Nulla facilisi. Ut scelerisque purus at tristique bibendum.
			</Paragraph>
		</Content>
	</Card>
);

const tooltipIconSizeExample = () => (
	<>
		<InlineText size="large">
			<Tooltip
				variant={{
					type: "text",
					icon: <Icon size="large">{Tooltip.icon}</Icon>,
				}}
				content="An SEO Activity Plan is a toolbox for customizing an adjustable workflow, allowing users to engage with all aspects of Search Engine Optimization (SEO) to meet their website goals and challenges."
			>
				Activity plans
			</Tooltip>
		</InlineText>
		<br />
		<InlineText size="medium">
			<Tooltip
				variant={{
					type: "text",
					icon: <Icon size="medium">{Tooltip.icon}</Icon>,
				}}
				content="An SEO Activity Plan is a toolbox for customizing an adjustable workflow, allowing users to engage with all aspects of Search Engine Optimization (SEO) to meet their website goals and challenges."
			>
				Activity plans
			</Tooltip>
		</InlineText>
		<br />
		<InlineText size="small">
			<Tooltip
				variant={{
					type: "text",
					icon: <Icon size="small">{Tooltip.icon}</Icon>,
				}}
				content="An SEO Activity Plan is a toolbox for customizing an adjustable workflow, allowing users to engage with all aspects of Search Engine Optimization (SEO) to meet their website goals and challenges."
			>
				Activity plans
			</Tooltip>
		</InlineText>
	</>
);

const tooltipIconExample = () => (
	<InlineText>
		<Tooltip
			variant={{
				type: "text",
				icon: (
					<Icon size={Tooltip.iconSize}>
						<IconPotentialIssue />
					</Icon>
				),
			}}
			content="Potential issues need to be verified by a human being."
		>
			Potential issue
		</Tooltip>
	</InlineText>
);

const iconOnlyExample = () => (
	<Tooltip variant={{ type: "icon-only" }} content="Desktop device">
		<Icon>
			<IconDesktop />
		</Icon>
	</Tooltip>
);

const interactiveExample = () => (
	<Tooltip variant={{ type: "interactive" }} content="Delete item">
		<Button onClick={() => console.log("clicked")}>
			<Icon>
				<IconDelete />
			</Icon>
		</Button>
	</Tooltip>
);

const missingDataTextExample = () => (
	<Tooltip
		variant={{ type: "text" }}
		content="The page doesn't contain enough text to measure readability."
	>
		Unavailable
	</Tooltip>
);

const missingDataIconOnlyExample = () => (
	<Tooltip variant={{ type: "icon-only" }} content="No data available">
		<Icon size={Tooltip.iconSize}>{Tooltip.icon}</Icon>
	</Tooltip>
);

const placementExample = () => (
	<Tooltip
		variant={{ type: "text", learnMoreAbout: "this tooltip's preferred placement" }}
		content="My preferred placement is to the right."
		placement="right"
	>
		<InlineText>Tooltip placement</InlineText>
	</Tooltip>
);
